<template>
  <div class="balances">
    <div v-if="!isSupplier" class="actions-wrapper">
      <rcc-button @click="isShowRegionsSelectModal = true">
        Фильтр по регионам
      </rcc-button>

      <rcc-button
        color="success"
        :is-loading="isExcelUploadLoading"
        @click="handleExcelUploadClick"
      >
        Выгрузить в Excel
      </rcc-button>
    </div>

    <component ref="balancesTable" :is="component" />

    <rcc-regions-select-modal
      v-model="isShowRegionsSelectModal"
      :save-callback="handleRegionsSelect"
      :selected-regions="selectedRegions"
      max-width="700px"
    />
  </div>
</template>

<script>
import getXls from '@/utils/get-xls'

import RccButton from 'Components/controls/rcc-button'
import RccRegionsSelectModal from './components/regions-select-modal'

const RccCommonBalances = () => import('./components/common.vue')
const RccCommonRccBalancesForSupplierRoleBalances = () => import('./components/supplier.vue')

export default {
  components: {
    RccButton,
    RccRegionsSelectModal
  },

  data() {
    return {
      isExcelUploadLoading: false,
      isShowRegionsSelectModal: false,
      selectedRegions: []
    }
  },

  computed: {
    isSupplier() {
      return this.$store.user.role === this.$constants.users.supplier
    },

    component() {
      return this.isSupplier ? RccCommonRccBalancesForSupplierRoleBalances : RccCommonBalances
    }
  },

  methods: {
    handleExcelUploadClick() {
      this.isExcelUploadLoading = true

      this.$apiMethods.suppliers.getExcel(this.$refs.balancesTable?.getFiltersQuery() || {})
        .then(response => {
          getXls.bind(this)(response)
        })
        .finally(() => { this.isExcelUploadLoading = false })
    },

    handleRegionsSelect(regions) {
      this.selectedRegions = regions
      this.$refs.balancesTable.loadData(null, { region_ids: regions })
    }
  }
}
</script>

<style lang="scss" scoped>
.balances {
  display: flex;
  height: 100%;
  flex-direction: column;
  padding: $base-page-padding;

  .actions-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
  }
}
</style>
